<template>
  <div class="component-upload-image">
    <el-upload
      :accept="accept"
      :action="uploadImgUrl"
      :list-type="type"
      :file-list="img?[{name:name || '',url:http+img}]:[]"
      :show-file-list="type=='fileList'"
      :on-success="handleUploadSuccess"
      :limit="limit"
      ref="upload" 
      :on-remove="handleUploadRemove"
      :on-preview="handlePictureCardPreview">
      <template v-if="type=='picture-card'">
        <img v-if="img" :src="img.indexOf('http')!=-1?img:(http+img)" class="avatar">
        <div v-if="img" class="op_btns"><i @click.stop="handlePictureCardPreview" class="el-icon-zoom-in"></i><i @click.stop="handleUploadRemove" class="el-icon-delete"></i></div>
        <i v-else class="el-icon-plus"></i>
      </template>
      <el-button type="primary" v-if="type=='fileList'">点击上传</el-button>
      <el-button type="primary" v-if="type=='button'">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      http:''
    };
  },
  props: {
    limit: {
      type: Number,
      default: 100
    },
    img:'',
    name:'',
    form:'',
    formkey:'',
    accept:{
      type:String,
      default:'image/*'
    },
    type:{
      type:String,
      default:'picture-card'
    },
	// 上传的图片服务器地址
	uploadImgUrl:{
      type:String,
      default:"/backend/Upload/image",
    }
  },

  created() {
    this.http=this.$http  
  },
  methods: {
    clearFiles(){
      this.$refs.upload.clearFiles();
    },
    handlePictureCardPreview(file){
      if(this.type=='picture-card'){
        // console.log(this.img);
        this.$emit("PicturePreview", {url:(this.img.indexOf('http')!=-1?this.img:(this.http+this.img))});
      }else{
        this.$emit("PicturePreview",file);
      }

    },
    handleUploadSuccess(res,file, fileList) {
      if(res.status==200){
        let data={
          url:'/storage/'+res.data.save_path.split('storage/')[1],
          id:res.data.id,
          form:this.form,
          key:this.formkey,
          name:file.file_name
        }
        this.$emit("handleUploadSuccess", data);
      }else{
        this.$message.error(res.message);
      }
    },
    handleUploadRemove( file, fileList){
      let data={
        form:this.form,
        key:this.formkey
      }
      this.$emit("handleUploadRemove", data);
    },
  },
  watch: {
    'formkey':function(){
      this.fileList=[{name:'',url:this.http+this[form][formkey]}]
    }
  },
};
</script>

<style scoped lang="scss">
.avatar {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.op_btns{display:flex; opacity:0; align-items:center;width: 100%; transition:0.3s;
  height: 100%; position:absolute; left:0; top:0; justify-content: center; background:rgba(0,0,0,0.7); }
  .op_btns:hover{ opacity:1}
 .op_btns i{ margin:0 10px;color:#fff; font-size:20px;}
</style>
