<template>
    <div class="container_con">
        <el-card>
            <div class="con_title"><span></span> 新增产品</div>
            <el-form ref="form" :rules="rules" :model="form" label-width="140px">
                <div class="form_box">
                    <div class="con_left">
                        <el-form-item :label="!form.id?'产品编号前缀：':'产品编号：'" prop="product_no">
                            <el-select v-model="form.product_no" v-if="!form.id" placeholder="请选择"
                                style="width: 100%;; margin-right: 15px;" filterable>
                                <el-option :label="item.value+'__'+item.title" v-for="(item,index) in zjlist"
                                    :key="index" :value="item.value"></el-option>
                            </el-select>
                            <el-input v-if="form.id" disabled style="width: 100%;" v-model="form.product_no"
                                placeholder="请输入产品编号" />
                        </el-form-item>
                        <el-form-item label="品名：" prop="product_name">
                            <div class="box_input">
                                <div v-for="(item, i) in form.product_name" :key="i" class="flex_ac">
                                    <el-input v-model="item.product_name" @blur="get_zjm(i)" class="flex1"
                                        placeholder="请输入品名" style="margin-right:10px;margin-bottom:10px" />
                                    <el-input v-model="item.mnemonic_code" class="flex1" placeholder="助记码" 
                                        style="margin-bottom:10px" />
                                    <span class="cp flex_s" v-if="form.product_name.length>1"
                                        @click="form.product_name.splice(i,1)"><img
                                            src="../../assets/icon/Icon_delete.png" alt="" /></span>
                                </div>
                            </div>
                            <div style="font-size:14px;color: #1676E0">
                                <sapn class="cp" @click="addHandle">添加品名</sapn>
                            </div>
                        </el-form-item>
                        <el-form-item label="规格：" prop="spec_value_id">
                            <!-- <el-select v-model="form.spec_value_id" placeholder="请选择" style="width:100%" filterable>
                                <el-option :label="item.title" v-for="(item,index) in speclist" :key="index"
                                    :value="item.spec_value_id"></el-option>
                            </el-select> -->
                            <el-input style="width: 100%;" v-model="form.spec_value_id"
                                placeholder="请输入产品规格" />
                        </el-form-item>
                        <el-form-item label="型号：" prop="model">
                            <el-input v-model="form.model" placeholder="请输入型号" />
                        </el-form-item>
                        <el-form-item label="产品注册证名称:" prop="register_cert_name">
                            <el-input v-model="form.register_cert_name" placeholder="请输入产品注册证名称" />
                        </el-form-item>
                        <el-form-item label="注册证号：">
                            <div class="box_input">
                                <div v-for="(item, i) in form.product_register_cert" :key="i" class="flex_ac">
                                    <el-input v-model="item.register_cert_no" class="flex1" placeholder="请输入注册证号"
                                        style="margin-bottom:10px; margin-right: 10px;" />
                                    <el-date-picker v-model="item.register_cert_expire" type="date" class="flex1"
                                        value-format="YYYY-MM-DD" placeholder="选择到期日期" style="margin-bottom:10px">
                                    </el-date-picker>
                                    <span class="cp flex_s" v-if="form.product_register_cert.length>1"
                                        @click="form.product_register_cert.splice(i,1)"><img
                                            src="../../assets/icon/Icon_delete.png" alt="" /></span>
                                </div>
                            </div>
                            <div style="font-size:14px;color: #1676E0">
                                <span class="cp" @click="addNumHandle">添加注册证号</span>
                            </div>
                        </el-form-item>

                        <el-form-item label="包装单位" prop="pack_unit_id">
                            <el-select v-model="form.pack_unit_id" placeholder="请选择" style="width:100%" filterable>
                                <el-option :label="item.title" v-for="(item,index) in bzlist" :key="index"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="生产厂家:" prop='manufacturer'>
                            <el-input v-model="form.manufacturer" placeholder="请输入生产厂家" />
                        </el-form-item>
                        <el-form-item label="产地:" prop='origin'>
                            <el-input v-model="form.origin" placeholder="请输入产地" />
                        </el-form-item>
                        <el-form-item label="类别:" prop='type'>
                            <el-select v-model="form.type" placeholder="请选择" style="width:100%" filterable>
                                <el-option :label="item.title" v-for="(item,index) in lblist" :key="index"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="con_right">
                        <el-form-item label="产品分类：" prop="category_id">
                            <el-cascader style="width:100%" v-model="form.category_id" :options="fllist"
                                :props="{ expandTrigger: 'hover',checkStrictly: true,emitPath:false,value:'id',label:'title',children:'_child'}"
                                clearable></el-cascader>
                        </el-form-item>
                        <el-form-item label="储存条件:" prop="storage_condition_id">
                            <el-select v-model="form.storage_condition_id" placeholder="请选择" style="width:100%"
                                filterable>
                                <el-option :label="item.title" v-for="(item,index) in cclist" :key="index"
                                    :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="进价:" prop="purchase_price">
                            <el-input v-model="form.purchase_price" placeholder="请输入进价" style="width:120px" />
                            元
                        </el-form-item>
                        <el-form-item label="零售价:" prop="price">
                            <el-input v-model="form.price" placeholder="请输入零售价" style="width:120px" />
                            元
                        </el-form-item>
                        <el-form-item label="库存上限:" prop="stock_upper">
                            <el-input-number style="width: 100%;" v-model="form.stock_upper" controls-position="right"
                                :min="0" />
                        </el-form-item>
                        <el-form-item label="库存下限:" prop="stock_lower">
                            <el-input-number style="width: 100%;" v-model="form.stock_lower" controls-position="right"
                                :min="0" />
                        </el-form-item>
                        <el-form-item label="Code编码:" prop="coding">
                            <el-input v-model="form.coding" placeholder="请输入Code编码" />
                        </el-form-item>
                        <el-form-item label="组件编码:" prop="module_coding">
                            <el-input v-model="form.module_coding" placeholder="请输入组件编码" />
                        </el-form-item>
                        <el-form-item label="医保编码:" prop="medical_insurance_coding">
                            <el-input v-model="form.medical_insurance_coding" placeholder="请输入医保编码" />
                        </el-form-item>
                        <el-form-item label="自定义编码:">
                            <div class="box_input">
                                <div class="flex_ac" v-for="(item, i) in form.product_customize_coding" :key="i">
                                    <el-input v-model="item.customize_coding" class="flex1" placeholder="请输入自定义编码"
                                        style="margin-bottom:10px;" />
                                    <span class="cp flex_s" v-if="form.product_customize_coding.length>1"
                                        @click="form.product_customize_coding.splice(i,1)"><img
                                            src="../../assets/icon/Icon_delete.png" alt="" /></span>
                                </div>
                            </div>
                            <div style="font-size:14px;color: #1676E0">
                                <span class="cp" @click="addCodHandle">添加编码</span>
                            </div>
                        </el-form-item>
                        <!-- <el-form-item label="产品申请原因:" prop="apply_reason">
              <el-input v-model="form.apply_reason" placeholder="请输入产品申请原因" />
            </el-form-item> -->
                    </div>
                </div>
                <el-form-item label="产品证件:" prop="imageIds">
                    <div class="flex flex_warp">
                        <div class="picture-card-img" v-for="(item,index) in imgs" :key="index">
                            <img :src="$http+item.url" class="picture-card-imgs" alt="">
                            <div class="op_btns"><i @click.stop="PicturePreview({url:$http+item.url})"
                                    class="el-icon-zoom-in"></i><i @click.stop="handleUploadRemove(index)"
                                    class="el-icon-delete"></i></div>
                        </div>
                        <SigleUpload accept="image/*" type="picture-card" @PicturePreview="PicturePreview"
                            @handleUploadSuccess="handleUploadSuccess"></SigleUpload>
                    </div>
                </el-form-item>
                <el-form-item label="备注:" prop='remark'>
                    <el-input type="textarea" v-model="form.remark" placeholder="请输入备注" style="width:450px" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
    <div class="bottom_btn">
        <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
    </div>

    <!-- 上传图片预览 -->
    <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
        <img style="width: 100%;" :src="uploadImageUrl" alt="">
    </el-dialog>

</template>

<script>
    // 上传组件
    import SigleUpload from "@/components/sigleUpload";
    export default {
        name: "addList",
        components: {
            SigleUpload
        },
        data() {
            return {
                id: '',
                is_ajax: false,
                form: {
                    product_name: [{
                        'product_name': '',
                        'mnemonic_code': '',
                        'id': ''
                    }],
                    product_register_cert: [{
                        'register_cert_no': '',
                        'id': ''
                    }],
                    product_customize_coding: [{
                        'customize_coding': '',
                        'id': ''
                    }],
                    product_no:'',
                    register_cert_name:'',
                    pack_unit_id:'',
                    model:'',
                    spec_value_id:'',
                    purchase_price:'',
                    price:'',
                    manufacturer:'',
                    storage_condition_id:'',
                    type:'',
                    origin:'',
                    stock_upper:'',
                    stock_lower:'',
                    coding:'',
                    module_coding:'',
                    medical_insurance_coding:'',
                    imageIds:'',
                    category_id:'',
                },
                rules: {
                    product_no: [{
                        required: true,
                        message: "产品编号前缀不能为空",
                        trigger: "blur"
                    }],
                    // register_cert_name: [{
                    //     required: true,
                    //     message: "注册证名称不能为空",
                    //     trigger: "blur"
                    // }],
                    pack_unit_id: [{
                        required: true,
                        message: "包装单位不能为空",
                        trigger: "blur"
                    }],
                    // model: [{
                    //     required: true,
                    //     message: "型号不能为空",
                    //     trigger: "blur"
                    // }],
                    // spec_value_id: [{
                    //     required: true,
                    //     message: "规格不能为空",
                    //     trigger: "blur"
                    // }],
                    // purchase_price: [{
                    //     required: true,
                    //     message: "进价不能为空",
                    //     trigger: "blur"
                    // }],
                    // price: [{
                    //     required: true,
                    //     message: "零售价不能为空",
                    //     trigger: "blur"
                    // }],
                    // manufacturer: [{
                    //     required: true,
                    //     message: "生产厂家不能为空",
                    //     trigger: "blur"
                    // }],
                    // storage_condition_id: [{
                    //     required: true,
                    //     message: "存储条件不能为空",
                    //     trigger: "blur"
                    // }],
                    // type: [{
                    //     required: true,
                    //     message: "类别不能为空",
                    //     trigger: "blur"
                    // }],
                    
                    // origin: [{
                    //     required: true,
                    //     message: "产地不能为空",
                    //     trigger: "blur"
                    // }],
                    // stock_upper: [{
                    //     required: true,
                    //     message: "库存上限不能为空",
                    //     trigger: "blur"
                    // }],
                    // stock_lower: [{
                    //     required: true,
                    //     message: "库存下限不能为空",
                    //     trigger: "blur"
                    // }],
                    // coding: [{
                    //     required: true,
                    //     message: "Code编码不能为空",
                    //     trigger: "blur"
                    // }],
                    // module_coding: [{
                    //     required: true,
                    //     message: "组件编码不能为空",
                    //     trigger: "blur"
                    // }],
                    // medical_insurance_coding: [{
                    //     required: true,
                    //     message: "医保编码不能为空",
                    //     trigger: "blur"
                    // }],
                    // imageIds: [{
                    //     required: true,
                    //     message: "产品证件不能为空",
                    //     trigger: "blur"
                    // }],
                    // category_id: [{
                    //     required: true,
                    //     message: "产品分类不能为空",
                    //     trigger: "blur"
                    // }]
                },
                cclist: [],
                speclist: [],
                zjlist: [],
                bzlist: [],
                lblist: [],
                fllist:[],
                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false,
                imgs: []
            };
        },
        created() {
            this.id = this.$route.query.id || '';
            if (this.id) {
                this.rules.product_no[0].required = false;
                this.get_data()
            }
            this.getOptions()
        },
        methods: {
            // 上传图片--s
            PicturePreview(file) {
                this.uploadImageUrl = file.url;
                this.uploadImgVisible = true;
            },
            handleUploadSuccess(res) {
                this.imgs.push(res);
                this.setimageIds()
            },
            handleUploadRemove(index) {
                this.imgs.splice(index, 1);
                this.setimageIds()
            },
            setimageIds() {
                let ids = [];
                for (let i = 0; i < this.imgs.length; i++) {
                    ids.push(this.imgs[i].id)
                }
                this.form.imageIds = ids.join(',')
            },
            get_zjm(index) {
                let _val = this.form.product_name[index].product_name;
                if (_val) {
                    this.$httpGet("/backend/product.Product/getMnemonicCode", {
                        product_name: _val
                    }).then((res) => {
                        if (res.status == 200) {
                            this.form.product_name[index].mnemonic_code = res.data
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    this.form.product_name[index].mnemonic_code = ''
                }
            },
            addHandle() {
                this.form.product_name.push({
                    id: '',
                    product_name: '',
                    mnemonic_code: ''
                })
            },
            addNumHandle() {
                this.form.product_register_cert.push({
                    register_cert_no: '',
                    id: ''
                })
            },
            addCodHandle() {
                this.form.product_customize_coding.push({
                    customize_coding: '',
                    id: ''
                })
            },
            // 表单重置
            reset(pid) {
                this.form = {
                    product_name: [{
                        'product_name': '',
                        'mnemonic_code': '',
                        'id': ''
                    }],
                    product_register_cert: [{
                        'register_cert_no': '',
                        'id': ''
                    }],
                    product_customize_coding: [{
                        'customize_coding': '',
                        'id': ''
                    }],
                    register_cert_name:'',
                    pack_unit_id:'',
                    model:'',
                    spec_value_id:'',
                    purchase_price:'',
                    price:'',
                    manufacturer:'',
                    storage_condition_id:'',
                    type:'',
                    origin:'',
                    stock_upper:'',
                    stock_lower:'',
                    coding:'',
                    module_coding:'',
                    medical_insurance_coding:'',
                    imageIds:'',
                    category_id:'',
                    product_no:this.zjlist.length>0?this.zjlist[0].value:''
                };
                this.imgs = [];
                if (this.$refs['form']) {
                    this.$refs['form'].clearValidate();
                }
            },
            /** 提交按钮 */
            submitForm: function(form) {
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        
                        if (form == 'form') {
                            let _data = {
                                ...this.form
                            };
                            let arr = [];
                            for (let i = 0; i < _data.product_name.length; i++) {
                                if(!_data.product_name[i].product_name){
                                    this.$message.error('品名不能为空');
                                    return false;
                                }
                                arr.push({
                                    product_name: _data.product_name[i].product_name || null,
                                    mnemonic_code: _data.product_name[i].mnemonic_code || null
                                })
                            }
                            _data.productArray = arr;
                            let arr2 = [];
                            for (let i = 0; i < _data.product_register_cert.length; i++) {
                                console.log(_data.product_register_cert[i].register_cert_no==false)
                                arr2.push({
                                    register_cert_no: _data.product_register_cert[i].register_cert_no || null,
                                    register_cert_expire: _data.product_register_cert[i].register_cert_expire || null
                                })
                            }
                            _data.registerCertArray = arr2;

                            let arr3 = [];
                            for (let i = 0; i < _data.product_customize_coding.length; i++) {
                                arr3.push(_data.product_customize_coding[i].customize_coding || null)
                            }
                            
                            _data.customizeCodingArray = arr3;
                            
                            console.log(JSON.stringify(_data))
                            this.is_ajax = true;
                            this.$httpPost("/backend/product.Product/save", _data).then((res) => {
                                if (res.status == 200) {
                                    this.$message.success(this.form.id ? '更新成功' : '新增成功');
                                    if(this.form.id){
                                        this.$router.go(-1)
                                    }else{
                                        // this.reset();
                                        this.$router.go(-1)
                                    }
                                    
                                } else {
                                    this.$message.error(res.message);
                                }
                                this.is_ajax = false;
                            }).catch((err) => {
                                console.log(err);
                                this.is_ajax = false;
                            });
                        }
                    }
                });
            },
            get_data() {
                this.$httpGet("/backend/product.Product/read", {
                    product_id: this.id
                }).then((res) => {
                    if (res.status == 200) {
                        let _data = res.data;
                        let img = [];
                        let ids = [];
                        for (let i = 0; i < _data.product_image.length; i++) {
                            img.push({
                                id: _data.product_image[i].file_id,
                                url: '/storage/' + _data.product_image[i].save_path.split('/storage/')[
                                    1]
                            });
                            ids.push(_data.product_image[i].file_id)
                        }
                        
                        this.imgs = img;
                        this.form = res.data;
                        if(res.data.product_register_cert.length==0){
                            this.form.product_register_cert= [{
                                'register_cert_no': '',
                                'id': ''
                            }]
                        }
                        if(res.data.product_customize_coding.length==0){
                            this.form.product_customize_coding= [{
                                'customize_coding': '',
                                'id': ''
                            }]
                        }
                        this.form.spec_value_id=this.form.spec_value_name;
                        if(this.form.type==0){
                            this.form.type=''
                        }
                        if(this.form.storage_condition_id==0){
                            this.form.storage_condition_id=''
                        }
                        if(this.form.category_id==0){
                            this.form.category_id=''
                        }
                        
                        this.form.imageIds = ids.join(',');
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            getOptions() {
                this.$httpGet("/backend/product.StorageCondition/index", {
                    size: 1000000
                }).then((res) => {
                    if (res.status == 200) {
                        this.cclist = res.data.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
                // this.$httpGet("/backend/product.SpecValue/index", {
                //     size: 1000000
                // }).then((res) => {
                //     if (res.status == 200) {
                //         this.speclist = res.data;
                //     } else {
                //         this.$message.error(res.message);
                //     }
                // }).catch((err) => {
                //     console.log(err);
                // });


                this.$httpGet("/backend/SysConfig/index", {
                    size: 10000000,
                    groups: '产品编号前缀'
                }).then((res) => {
                    if (res.status == 200) {
                        this.zjlist = res.data.data;
                        if(!this.id && this.zjlist.length>0){
                            this.form.product_no=this.zjlist[0].value
                        }
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
                this.$httpGet("/backend/product.PackUnit/index", {
                    size: 10000000
                }).then((res) => {
                    if (res.status == 200) {
                        this.bzlist = res.data.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });

                this.$httpGet("/backend/product.ProductType/index", {
                    size: 10000000
                }).then((res) => {
                    if (res.status == 200) {
                        this.lblist = res.data.data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
                
                this.$httpGet("/backend/product.Category/index", {}).then((res) => {
                    if (res.status == 200) {
                        this.fllist = res.data.list;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });


            }
        },
    };
</script>

<style lang="scss" scoped>
    // .box_input {
    //   div {
    //     &:first-child {
    //       img {
    //         display: none;
    //       }
    //     }
    //   }
    // }
    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
        width: 80px;
        height: 80px;
    }

    ::v-deep .el-upload--picture-card {
        width: 80px;
        height: 80px;
        line-height: 80px;
    }
</style>
